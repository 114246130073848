import { navigate } from 'gatsby'
import React from 'react'

const NotFoundPage: React.FC = () => {
  if (typeof window !== 'undefined') {
    navigate('/', { replace: true })
  }
  return <></>
}

export default NotFoundPage
